// Main colors
@app-theme-color: #0e9aef;
@app-theme-nav-bg: #3f3f3f;
@app-theme-a-color: #9ea6b9;
@app-theme-page-bg: #f4f6fa;
@app-theme-a-nav-color: #b99e9e;

// Custom style
.app-theme .minimalize-styl-2 {
  margin: 14px 5px 5px 30px;
}

.app-theme .navbar-top-links li:last-child {
  margin-right: 30px;
}

.app-theme.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}

.app-theme .spin-icon {
  background: @app-theme-color !important;
}

body.boxed-layout.app-theme #wrapper {
  background: @app-theme-nav-bg;
}

.app-theme .nav-header {
  background: @app-theme-color;
  background: url('patterns/header-profile-app-theme.png');
}

.app-theme.mini-navbar .nav-second-level {
  background: @app-theme-nav-bg;
}

.app-theme .breadcrumb {
  background: transparent;
}

.app-theme .page-heading {
  border: none;
}

.app-theme .nav > li.active {
  background: darken(@app-theme-nav-bg, 2%);
}

.fixed-nav.app-theme .navbar-fixed-top {
  background: #fff;
}

.app-theme .nav > li > a {
  color: @app-theme-a-nav-color;
}

.app-theme .navbar-static-top .nav > li > a {
  color: @app-theme-a-color;
}

.app-theme .nav > li.active > a {
  color: #fff;
}

.app-theme .navbar-minimalize {
  background: @app-theme-color;
  border-color: @app-theme-color;
}

body.app-theme {
  background: @app-theme-nav-bg;
}

.app-theme .navbar-static-top {
  background: #ffffff;
}

.app-theme .dashboard-header {
  background: transparent;
  border-bottom: none !important;
  border-top: none;
  padding: 20px 30px 10px 30px;
}

.app-theme .wrapper-content {
  padding: 30px 15px;
}

.app-theme #page-wrapper {
  background: @app-theme-page-bg;
}

.app-theme .ibox-title, .app-theme .ibox-content {
  border-width: 1px;
}

.app-theme .ibox-content:last-child {
  border-style: solid solid solid solid;
}

.app-theme .nav > li.active {
  border: none;
}

.app-theme .nav-header {
  padding: 35px 25px 25px 25px;
}

.app-theme .nav-header a.dropdown-toggle {
  color: #fff;
  margin-top: 10px;
}



.app-theme .profile-element {
  text-align: center;
}

.app-theme .img-circle {
  border-radius: 5px;
}

.app-theme .navbar-default .nav > li > a:hover, .app-theme .navbar-default .nav > li > a:focus {
  background: darken(@app-theme-nav-bg, 2%);
  color: #fff;
}

.app-theme .nav.nav-tabs > li.active > a {
  color: #555;
}
.app-theme .nav.nav-tabs > li.active {
  background: transparent;
}